import { useContext, useEffect, useMemo, useState } from "react";
// import Button from "../../components/button";
import Modal from "../../components/modal";
import TextInput from "../../components/text-input";
import {
  Attachment,
  EntityTypePerson,
  KybNode,
  ToBeSavedData,
  UnlistedCompanyData,
} from "../../types";
import SelectInput from "../../components/select-input";
import {
  DataTypes,
  EntityTypes,
  OWNERSHIP_PERCENTAGE_ERROR,
  UnlistedCompanyAttachmentType,
  UnlistedCompanyPersonType,
} from "../../constants";
import { ComponentContext } from "../kyb-kyc";
import Badge from "../../components/badge";
import EmptyPlaceholder from "../../components/empty-placeholder";
import emailChecker from "../../helpers/email-checker";
import OverlayLoading from "../../components/overlay-loading";
import AttachmentsModals from "../../components/attachments";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import determineDisabled from "../../helpers/kyb-kyc/determine-disabled";
import { api } from "../../api";
import { FetchError } from "../../lib/fetcher";

export default function UnlistedCompany() {
  const { nodeData, kybStatus } = useContext(ComponentContext);

  const unlistedCompany = useMemo(() => {
    if (!nodeData || !nodeData.entityData) return {};
    return nodeData.entityData as UnlistedCompanyData;
  }, [nodeData]);

  const [showDirectorModal, setShowDirectorModal] = useState(false);
  const [showEntityPersonModal, setShowEntityPersonModal] = useState({
    show: false,
    type: "",
  });
  const [entityTypePerson, setEntityTypePerson] = useState<EntityTypePerson>(
    {}
  );
  const [unlistedCompanyData, setUnlistedCompanyData] = useState<
    Partial<UnlistedCompanyData>
  >({});
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false);
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const [areyousure, setAreyousure] = useState(false);
  const [areYouSureDirector, setAreYouSureDirector] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [attachment, setAttachment] = useState<Attachment | undefined>(
    undefined
  );
  const [personToDelete, setPersonToDelete] = useState<
    EntityTypePerson | undefined
  >(undefined);
  const [data, setData] = useState<KybNode>();
  useMemo(() => {
    if (!nodeData) return;
    setData(nodeData);
  }, [nodeData]);

  useEffect(() => {
    if (!data) return;
    localStorage.setItem(
      "NumOfAttachments",
      data.attachments?.length.toString() ?? "0"
    );
  }, [data]);

  function mutateNodeAttachments(attachment: Attachment) {
    if (!data) return;
    setData({
      ...data,
      attachments: [...(data.attachments ?? []), attachment],
    });
  }

  function removeAttachment(id?: number) {
    if (!data) return;
    const attachments = (data.attachments ?? []).filter(a => a.id != id);
    setData({
      ...data,
      attachments,
    });
  }

  const beneficialOwnerPeople = useMemo(() => {
    if (!unlistedCompanyData || !unlistedCompanyData.entityPeople) return [];
    return unlistedCompanyData.entityPeople.filter(
      (e) => e.personType === UnlistedCompanyPersonType.BeneficialOwner
    );
  }, [unlistedCompanyData]);

  const controllerPeople = useMemo(() => {
    if (!unlistedCompanyData || !unlistedCompanyData.entityPeople) return [];
    return unlistedCompanyData.entityPeople.filter(
      (e) => e.personType === UnlistedCompanyPersonType.Controller
    );
  }, [unlistedCompanyData]);

  const directorPeople = useMemo(() => {
    if (!unlistedCompanyData || !unlistedCompanyData.entityPeople) return [];
    return unlistedCompanyData.entityPeople.filter(
      (e) => e.personType === UnlistedCompanyPersonType.Director
    );
  }, [unlistedCompanyData]);

  useEffect(() => {
    if (!unlistedCompany) return;
    setUnlistedCompanyData(unlistedCompany);
  }, [unlistedCompany]);

  useEffect(() => {
    if (!unlistedCompanyData) return;
    function handleChildSave() {
      const toBeSaveData: ToBeSavedData = {
        dataType: DataTypes.UnlistedCompanyData,
        data: unlistedCompanyData as UnlistedCompanyData,
      };
      localStorage.setItem("toBeSaved", JSON.stringify(toBeSaveData));
    }
    handleChildSave();
  }, [unlistedCompanyData]);

  async function handleDeletePerson() {
    if (!personToDelete || !unlistedCompanyData.entityPeople) return;
    setDeleteLoading(true);
    if (personToDelete.id) {
      const response = await api.deleteEntityPerson(personToDelete.id);
      if (response instanceof FetchError) {
        alert("Error deleting person");
        setDeleteLoading(false);
        setPersonToDelete(undefined);
        return;
      }
      const newEntityPeople = unlistedCompanyData.entityPeople.filter(
        (e) => e.id !== personToDelete.id
      );
      setUnlistedCompanyData({
        ...unlistedCompanyData,
        entityPeople: newEntityPeople,
      });
      setPersonToDelete(undefined);
      setDeleteLoading(false);
    } else {
      const newEntityPeople = unlistedCompanyData.entityPeople.filter(
        (e) =>
          e.legalName !== personToDelete.legalName &&
          e.email !== personToDelete.email &&
          e.personType !== personToDelete.personType
      );
      setUnlistedCompanyData({
        ...unlistedCompanyData,
        entityPeople: newEntityPeople,
      });
      setPersonToDelete(undefined);
      setDeleteLoading(false);
    }
  }

  function AreYouSureDirector() {
    return (
      <Modal
        open={areYouSureDirector}
        setOpen={setAreYouSureDirector}
        dontShowHeader
        actionButtonName="Remove"
        actionButtonType="danger"
        onPressCancel={() => {
          setAreYouSureDirector(false);
        }}
        onPressAdd={() => {
          handleDeletePerson();
          setAreYouSureDirector(false);
        }}
      >
        <div className="sm:flex sm:items-start ">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-xl bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <div className="h3 text-lg font-semibold leading-6 text-gray-700">
              Attention!
            </div>
            <div className="mt-2 text-red-500">
              <p className="text-sm">
                Are you sure you want to remove{" "}
                <span className="font-bold text-gray-700">
                  {personToDelete?.legalName}
                </span>{" "}
                as a director for this entity?
              </p>
              <p className="text-sm">
                This action cannot be undone. All the information related to
                this director will be removed.
              </p>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  function handleAddDirector() {
    if (
      !entityTypePerson.legalName ||
      (entityTypePerson.email && !emailChecker(entityTypePerson.email))
    )
      return;
    setUnlistedCompanyData({
      ...unlistedCompanyData,
      entityPeople: [
        ...(unlistedCompanyData.entityPeople ?? []),
        {
          ...entityTypePerson,
          personType: UnlistedCompanyPersonType.Director,
        },
      ],
    });
    setShowDirectorModal(false);
    setEntityTypePerson({});
  }

  function handleAddBeneficialOwner() {
    if (
      !entityTypePerson.legalName ||
      !entityTypePerson.entityType ||
      !entityTypePerson.email ||
      !emailChecker(entityTypePerson.email) ||
      !entityTypePerson.ownershipPercentage ||
      +entityTypePerson.ownershipPercentage < 25 ||
      +entityTypePerson.ownershipPercentage >
        100 - beneficiaryOwnershipPercentage
    )
      return;
    setUnlistedCompanyData({
      ...unlistedCompanyData,
      entityPeople: [
        ...(unlistedCompanyData.entityPeople ?? []),
        {
          ...entityTypePerson,
          toBeKYC:
            entityTypePerson.entityType === EntityTypes.SoleTraderIndividual
              ? true
              : false,
          personType: UnlistedCompanyPersonType.BeneficialOwner,
        },
      ],
    });
    setShowEntityPersonModal({ show: false, type: "" });
    setEntityTypePerson({});
  }

  function handleRemovePerson(personType: UnlistedCompanyPersonType, email?: string) {
    const people = unlistedCompanyData.entityPeople?.filter((p) => 
      p.personType !== personType ||
      p.email !== email
    ) ?? [];
    setUnlistedCompanyData({
      ...unlistedCompanyData,
      entityPeople: [...people],
    });
  }

  function handleAddCompanyController() {
    if (
      !entityTypePerson.legalName ||
      !entityTypePerson.email ||
      !entityTypePerson.entityType ||
      !emailChecker(entityTypePerson.email)
    )
      return;
    setUnlistedCompanyData({
      ...unlistedCompanyData,
      entityPeople: [
        ...(unlistedCompanyData.entityPeople ?? []),
        {
          ...entityTypePerson,
          toBeKYC:
            entityTypePerson.entityType === EntityTypes.SoleTraderIndividual
              ? true
              : false,
          personType: UnlistedCompanyPersonType.Controller,
        },
      ],
    });
    setShowEntityPersonModal({ show: false, type: "" });
    setEntityTypePerson({});
  }

  const beneficiaryOwnershipPercentage = useMemo(() => {
    if (
      !unlistedCompanyData ||
      !unlistedCompanyData.entityPeople ||
      !beneficialOwnerPeople
    )
      return 0;
    const totalOwnershipPercentage = beneficialOwnerPeople.reduce(
      (acc, curr) => {
        const currOwnershipPercentage = curr.ownershipPercentage
          ? parseFloat(curr.ownershipPercentage as unknown as string)
          : 0;
        return acc + (currOwnershipPercentage ?? 0);
      },
      0
    );
    return parseFloat(totalOwnershipPercentage.toFixed(2));
  }, [unlistedCompanyData, beneficialOwnerPeople]);

  function Directors() {
    return (
      <div className="flex flex-col items-center w-full">
        <div className="flex flex-row justify-between items-center w-full">
          <div className="w-1/2">
            <p className="block text-md font-medium leading-6 text-apple-600 ">
              Directors
            </p>
            <p className="mt-1 text-xs text-gray-500">
              Please provide the name of each director.
            </p>
          </div>
          {!determineDisabled(kybStatus) ? (
            <div>
              <button
                className="flex w-full rounded-xl hover:bg-apple-500 bg-transparent border-2 border-apple-600 py-0.5 hover:text-white hover:border-apple-500 px-4 text-sm font-semibold text-apple-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-apple-600"
                onClick={() => setShowDirectorModal(true)}
              >
                Add Director
              </button>
            </div>
          ) : null}
        </div>
        <div className="flex flex-col w-full">
          {directorPeople.length < 1 ? (
            <EmptyPlaceholder>
              No directors added yet. Please add all company directors.
            </EmptyPlaceholder>
          ) : (
            directorPeople.map((director, index) => (
              <div
                key={index}
                className={
                  index < directorPeople.length - 1
                    ? `border-b border-gray-200`
                    : ``
                }
              >
                <div className="flex flex-row justify-between items-center py-4 w-full flex-wrap">
                  <div className="flex flex-row justify-start items-center w-1/2">
                    <span className="inline-flex h-8 w-8 items-center justify-center rounded-xl bg-apple-200 mr-4">
                      <span className="text-xs font-medium leading-none text-apple-800">
                        {director.legalName?.charAt(0).toUpperCase()}
                      </span>
                    </span>
                    <div className="">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        {director.legalName}
                      </p>
                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                        {director.email}
                      </p>
                    </div>
                  </div>
                  {!determineDisabled(kybStatus) ? (
                    <div className="flex flex-row justify-end items-center">
                      <button
                        className="flex w-full rounded-xl hover:bg-red-500 bg-transparent border-2 border-red-500 py-0.5 hover:text-white hover:border-red-500 px-4 text-sm font-semibold text-red-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                        onClick={() => {
                          setPersonToDelete(director);
                          setAreYouSureDirector(true);
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    );
  }

  function BeneficiaryOwners() {
    return (
      <div className="flex flex-col items-center w-full pt-4">
        <div className="flex flex-row justify-between items-center w-full ">
          <div className="w-1/2">
            <p className="block text-md font-medium leading-6 text-apple-600 ">
              Beneficial Owners
            </p>
          </div>

          <div>
            {beneficiaryOwnershipPercentage < 100 &&
            !determineDisabled(kybStatus) ? (
              <button
                className="flex w-full rounded-xl hover:bg-apple-500 bg-transparent border-2 border-apple-600 py-0.5 hover:text-white hover:border-apple-500 px-4 text-sm font-semibold text-apple-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-apple-600"
                onClick={() =>
                  setShowEntityPersonModal({
                    show: true,
                    type: UnlistedCompanyPersonType.BeneficialOwner,
                  })
                }
              >
                Add Owner
              </button>
            ) : null}
          </div>
        </div>
        <div className="flex flex-col w-full">
          {beneficialOwnerPeople.length < 1 ? (
            <EmptyPlaceholder>
              No beneficial owner added yet. Please add all beneficial owner.
            </EmptyPlaceholder>
          ) : (
            beneficialOwnerPeople.map((director, index) => (
              <div
                key={index}
                className={
                  index < beneficialOwnerPeople.length - 1
                    ? `border-b border-gray-200`
                    : ``
                }
              >
                <div className="flex flex-row justify-between items-center py-4 w-full flex-wrap">
                  <div className="flex flex-row justify-start items-center">
                    <span className="inline-flex h-8 w-8 items-center justify-center rounded-xl bg-apple-200 mr-4">
                      <span className="text-xs font-medium leading-none text-apple-800">
                        {director.legalName?.charAt(0).toUpperCase()}
                      </span>
                    </span>
                    <div className="">
                      <span className="flex flex-row items-center">
                        <p className="text-sm font-semibold leading-6 text-gray-900 truncate">
                          {director.legalName}
                        </p>
                        <p className=" pl-2 truncate text-xs leading-5 text-gray-500">
                          ({director.email})
                        </p>
                      </span>

                      <p className="truncate text-xs leading-5 text-gray-500">
                        {director.ownershipPercentage}% Ownership
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row justify-end items-center">
                    <Badge variant="disabled">{director.entityType}</Badge>
                  </div>
                  <div className="flex flex-row justify-end items-center">
                    <button
                      type="button"
                      className="flex w-full rounded-xl hover:bg-red-500 bg-transparent border-2 border-red-500 py-0.5 hover:text-white hover:border-red-500 px-4 text-sm font-semibold text-red-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                      onClick={() => {
                        if (confirm("Are you sure you want to delete this director?\n\nThis action cannot be undone. All the information related to this partner will be removed.")) {
                          handleRemovePerson(UnlistedCompanyPersonType.BeneficialOwner, director.email);
                        }
                      }}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    );
  }

  function CompanyControllers() {
    return (
      <div className="flex flex-col items-center w-full pt-4">
        <div className="flex flex-row justify-between items-center w-full ">
          <div className="w-1/2">
            <p className="block text-md font-medium leading-6 text-apple-600 ">
              Controllers
            </p>
            <p className="mt-1 text-xs text-gray-500">
              A <strong>Controller</strong> is someone who is elected or
              appointed to exercise more direct control over the company, by
              participating in the governance or senior executive activities of
              the customer.
            </p>
          </div>
          {!determineDisabled(kybStatus) ? (
            <div>
              <button
                className="flex w-full rounded-xl hover:bg-apple-500 bg-transparent border-2 border-apple-600 py-0.5 hover:text-white hover:border-apple-500 px-4 text-sm font-semibold text-apple-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-apple-600"
                onClick={() =>
                  setShowEntityPersonModal({
                    show: true,
                    type: UnlistedCompanyPersonType.Controller,
                  })
                }
              >
                Add Controller
              </button>
            </div>
          ) : null}
        </div>
        <div className="flex flex-col w-full px-2 sm:px-4">
          {controllerPeople.length < 1 ? (
            <EmptyPlaceholder>
              No company controller added yet. Please add one company controller.
            </EmptyPlaceholder>
          ) : (
            controllerPeople.map((controller, index) => (
              <div key={index} className="border-b border-gray-200">
                <div className="flex flex-row justify-between items-center py-4 w-full flex-wrap">
                  <div className="flex flex-row justify-start items-center">
                    <span className="inline-flex h-8 w-8 items-center justify-center rounded-xl bg-apple-200 mr-4">
                      <span className="text-xs font-medium leading-none text-apple-800">
                        {controller.legalName?.charAt(0).toUpperCase()}
                      </span>
                    </span>
                    <div>
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        {controller.legalName}
                      </p>
                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                        {controller.email}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row justify-end items-center">
                    <Badge variant="disabled">{controller.entityType}</Badge>
                  </div>
                  <div className="flex flex-row justify-end items-center">
                    <button
                      type="button"
                      className="flex w-full rounded-xl hover:bg-red-500 bg-transparent border-2 border-red-500 py-0.5 hover:text-white hover:border-red-500 px-4 text-sm font-semibold text-red-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                      onClick={() => {
                        if (confirm("Are you sure you want to delete this controller?\n\nThis action cannot be undone. All the information related to this partner will be removed.")) {
                          handleRemovePerson(UnlistedCompanyPersonType.Controller, controller.email);
                        }
                      }}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    );
  }

  function AddDirectorModal() {
    return (
      <Modal
        open={showDirectorModal}
        setOpen={setShowDirectorModal}
        title="Add Director"
        onPressAdd={() => handleAddDirector()}
      >
        <div className="flex flex-1 flex-col w-full">
          <TextInput
            label="Full Legal Name"
            type="text"
            value={entityTypePerson.legalName ?? ""}
            onChange={(e) => {
              setEntityTypePerson({
                ...entityTypePerson,
                legalName: e.target.value,
              });
            }}
            error={
              entityTypePerson.legalName === "" || !entityTypePerson.legalName
                ? 1
                : undefined
            }
          />

          <TextInput
            label="Email"
            type="email"
            onChange={(e) => {
              setEntityTypePerson({
                ...entityTypePerson,
                email: e.target.value,
              });
            }}
            error={
              entityTypePerson.email && !emailChecker(entityTypePerson.email)
                ? 1
                : undefined
            }
            errormesage="Please enter a valid email!"
          />
        </div>
      </Modal>
    );
  }

  function AddEntityPersonModal() {
    return (
      <Modal
        open={showEntityPersonModal.show}
        setOpen={() =>
          setShowEntityPersonModal({
            show: false,
            type: UnlistedCompanyPersonType.BeneficialOwner,
          })
        }
        onPressCancel={() => {
          showEntityPersonModal.type ===
          UnlistedCompanyPersonType.BeneficialOwner
            ? setShowEntityPersonModal({
                show: false,
                type: UnlistedCompanyPersonType.BeneficialOwner,
              })
            : setShowEntityPersonModal({
                show: false,
                type: UnlistedCompanyPersonType.Controller,
              });

          setEntityTypePerson({});
        }}
        title={
          showEntityPersonModal.type ===
          UnlistedCompanyPersonType.BeneficialOwner
            ? "Add Beneficial Owner"
            : "Add Company Controller"
        }
        onPressAdd={() =>
          showEntityPersonModal.type ===
          UnlistedCompanyPersonType.BeneficialOwner
            ? handleAddBeneficialOwner()
            : handleAddCompanyController()
        }
      >
        <div className="flex flex-1 flex-col w-full">
          <TextInput
            label="Full Legal Name"
            type="text"
            value={entityTypePerson.legalName ?? ""}
            onChange={(e) => {
              setEntityTypePerson({
                ...entityTypePerson,
                legalName: e.target.value,
              });
            }}
            error={
              entityTypePerson.legalName === "" || !entityTypePerson.legalName
                ? 1
                : undefined
            }
          />

          <TextInput
            label="Email"
            type="email"
            onChange={(e) => {
              setEntityTypePerson({
                ...entityTypePerson,
                email: e.target.value,
              });
            }}
            error={
              !entityTypePerson.email || !emailChecker(entityTypePerson.email)
                ? 1
                : undefined
            }
            errormesage="Please enter a valid email!"
          />

          <SelectInput
            label="Entity Type"
            onChange={(e) => {
              setEntityTypePerson({
                ...entityTypePerson,
                entityType: e.target.value as EntityTypes,
              });
            }}
            error={!entityTypePerson.entityType ? 1 : undefined}
          >
            <option value=""></option>
            {Object.entries(EntityTypes).map(([key, value]) => (
              <option key={key} value={value}>
                {value}
              </option>
            ))}
          </SelectInput>

          {showEntityPersonModal.type ===
          UnlistedCompanyPersonType.BeneficialOwner ? (
            <TextInput
              label={`Ownership Percentage (${(
                100 - beneficiaryOwnershipPercentage
              ).toFixed(2)}% remaining)`}
              type="number"
              inputMode="numeric"
              pattern="[0-9]*"
              onChange={(e) => {
                if (
                  parseFloat(e.target.value) >
                  100.0 - beneficiaryOwnershipPercentage
                ) {
                  alert(OWNERSHIP_PERCENTAGE_ERROR);
                  e.target.value = "";
                  return;
                } else {
                  setEntityTypePerson({
                    ...entityTypePerson,
                    ownershipPercentage: parseFloat(
                      (+e.target.value).toFixed(2)
                    ),
                  });
                }
              }}
              error={
                !entityTypePerson.ownershipPercentage ||
                +entityTypePerson.ownershipPercentage < 25 ||
                +entityTypePerson.ownershipPercentage >
                  100 - beneficiaryOwnershipPercentage
                  ? 1
                  : undefined
              }
              errormesage={"Ownership percentage must be 25% or more!"}
            />
          ) : null}
        </div>
      </Modal>
    );
  }

  return (
    <div className="w-full p-2 lg:p-4">
      {AddDirectorModal()}
      {AddEntityPersonModal()}
      {AreYouSureDirector()}
      {<OverlayLoading open={attachmentLoading || deleteLoading} />}
      <div className="flex flex-row justify-between items-center border-b border-gray-200 pb-8">
        <div className="w-1/2">
          <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
            Unlisted Company
          </h2>
          <p className="mt-1 text-xs text-gray-500">
            Please fill all the required information to proceed with your KYB
            application.
          </p>
        </div>

        {/* <div>
          <Button> Save Progress</Button>
        </div> */}
      </div>
      {!nodeData?.parentNodeId && (
        <>
          <div className="mt-4">{Directors()}</div>
          <div className="col-span-full border-b border-gray-200 py-4 px-6" />
        </>
      )}

      <p className="block text-md font-medium leading-6 text-apple-600 pt-4">
        Beneficial Owners
      </p>
      <p className="mt-1 text-xs text-gray-500">
        A <strong>Beneficial Owner</strong> is a sole trader/individual or
        entity who owns, or otherwise controls the business of an entity. Please
        provide the required details for all beneficial owners.
      </p>
      <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-12 px-2 sm:px-4">
        <div className="sm:col-span-8">
          <SelectInput
            disabled={
              beneficialOwnerPeople.length > 0 || controllerPeople.length > 0
            }
            label="Are there any beneficial owner/s who own 25% or more of the company?"
            onChange={(e) => {
              setUnlistedCompanyData({
                ...unlistedCompanyData,
                hasBeneficialOwners: !e.target.value
                  ? undefined
                  : e.target.value === "1"
                  ? true
                  : false,
              });
            }}
            value={
              unlistedCompanyData.hasBeneficialOwners === false
                ? 0
                : unlistedCompanyData.hasBeneficialOwners === true
                ? 1
                : ""
            }
            error={
              unlistedCompanyData.hasBeneficialOwners === undefined
                ? 1
                : undefined
            }
          >
            <option value={""}></option>
            <option value={1}>Yes</option>
            <option value={0}>No</option>
          </SelectInput>
        </div>
        <div className="col-span-full">
          {!unlistedCompanyData.hasBeneficialOwners
            ? null
            : unlistedCompanyData.hasBeneficialOwners
            ? BeneficiaryOwners()
            : null}
        </div>
      </div>

      {unlistedCompanyData.hasBeneficialOwners === false &&
      !nodeData?.parentNodeId ? (
        <>
          <div className="col-span-full border-b border-gray-200 py-4 px-6" />

          <div className="">{CompanyControllers()}</div>
        </>
      ) : null}
      <div className="w-full border-b border-gray-200 py-4 px-6" />
      {data ? (
        <div className="w-full">
          {AttachmentsModals({
            nodeData: data,
            openAttachmentModal,
            setOpenAttachmentModal,
            setAttachmentLoading,
            areyousure,
            setAreyousure,
            attachment,
            setAttachment,
            mutateNodeAttachments,
            removeAttachment,
            AttachmentType: UnlistedCompanyAttachmentType,
            kybStatus,
          })}
        </div>
      ) : null}
    </div>
  );
}
