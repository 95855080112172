import { useContext, useEffect, useMemo, useState } from "react";
import Modal from "../../components/modal";
import TextInput from "../../components/text-input";
import {
  Address,
  Attachment,
  EntityTypePerson,
  KybNode,
  PartnershipData,
  ToBeSavedData,
} from "../../types";
import AddressComponent from "../../components/address";
import SelectInput from "../../components/select-input";
import {
  AlternatePartnershipAttachmentType,
  DataTypes,
  EntityTypes,
  OWNERSHIP_PERCENTAGE_ERROR,
  PartnershipAttachmentType,
  PartnershipPersonType,
} from "../../constants";
import { ComponentContext } from "../kyb-kyc";
import Loading from "../../components/loading";
import Badge from "../../components/badge";
import EmptyPlaceholder from "../../components/empty-placeholder";
import emailChecker from "../../helpers/email-checker";
import AttachmentsModals from "../../components/attachments";
import OverlayLoading from "../../components/overlay-loading";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import determineDisabled from "../../helpers/kyb-kyc/determine-disabled";
import { api } from "../../api";
import { FetchError } from "../../lib/fetcher";

export default function Partnership() {
  const { nodeData, kybStatus } = useContext(ComponentContext);

  const partnership = useMemo(() => {
    if (!nodeData || !nodeData.entityData) return {} as PartnershipData;
    return nodeData.entityData as PartnershipData;
  }, [nodeData]);

  const [partnershipData, setPartnershipData] = useState<PartnershipData>({});
  const [showAddPartnerModal, setShowAddPartnerModal] = useState(false);
  const [showAddBeneficiaryModal, setShowAddBeneficiaryModal] = useState(false);
  const [entityTypePerson, setEntityTypePerson] = useState<EntityTypePerson>(
    {},
  );
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false);
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const [areyousure, setAreyousure] = useState(false);
  const [areYouSurePartner, setAreYouSurePartner] = useState(false);
  const [areYouSureBeneficiary, setAreYouSureBeneficiary] = useState(false);
  const [personToDelete, setPersonToDelete] = useState<
    EntityTypePerson | undefined
  >(undefined);
  const [attachment, setAttachment] = useState<Attachment | undefined>(
    undefined,
  );
  const [data, setData] = useState<KybNode>();
  useMemo(() => {
    if (!nodeData) return;
    setData(nodeData);
  }, [nodeData]);

  function mutateNodeAttachments(attachment: Attachment) {
    if (!data) return;
    setData({
      ...data,
      attachments: [...(data.attachments ?? []), attachment],
    });
  }

  function removeAttachment(id?: number) {
    if (!data) return;
    const attachments = (data.attachments ?? []).filter(a => a.id != id);
    setData({
      ...data,
      attachments,
    });
  }

  async function handleDeletePerson() {
    if (!personToDelete || !partnershipData.entityPeople) return;
    setDeleteLoading(true);
    if (personToDelete.id) {
      const response = await api.deleteEntityPerson(personToDelete.id);
      if (response instanceof FetchError) {
        alert(response.error);
        setDeleteLoading(false);
        setPersonToDelete(undefined);
        return;
      }
      const newEntityPeople = partnershipData.entityPeople?.filter(
        (p) => p.id !== personToDelete.id,
      );
      setPartnershipData({
        ...partnershipData,
        entityPeople: newEntityPeople,
      });
      setPersonToDelete(undefined);
      setDeleteLoading(false);
    } else {
      // delete from memory
      const newEntityPeople = partnershipData.entityPeople?.filter(
        (p) =>
          p.legalName !== personToDelete.legalName &&
          p.email !== personToDelete.email &&
          p.personType !== personToDelete.personType,
      );
      setPartnershipData({
        ...partnershipData,
        entityPeople: newEntityPeople,
      });
      setPersonToDelete(undefined);
      setDeleteLoading(false);
    }
  }

  useEffect(() => {
    if (!partnership) return;
    setPartnershipData(partnership);
  }, [partnership]);

  useEffect(() => {
    function handleSave() {
      if (!partnershipData) return;
      const toBeSavedData: ToBeSavedData = {
        dataType: DataTypes.PartnershipData,
        data: partnershipData,
      };
      localStorage.setItem("toBeSaved", JSON.stringify(toBeSavedData));
    }
    handleSave();
  }, [partnershipData]);

  useEffect(() => {
    if (!data) return;
    localStorage.setItem(
      "NumOfAttachments",
      data.attachments?.length.toString() ?? "0",
    );
  }, [data]);

  function AreYouSurePartner() {
    return (
      <Modal
        open={areYouSurePartner}
        setOpen={setAreYouSurePartner}
        dontShowHeader
        actionButtonName="Remove"
        actionButtonType="danger"
        onPressCancel={() => {
          setAreYouSurePartner(false);
        }}
        onPressAdd={() => {
          setAreYouSurePartner(false);
          handleDeletePerson();
          setPersonToDelete(undefined);
        }}
      >
        <div className="sm:flex sm:items-start ">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-xl bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <div className="h3 text-lg font-semibold leading-6 text-gray-700">
              Attention!
            </div>
            <div className="mt-2 text-red-500">
              <p className="text-sm">
                Are you sure you want to remove{" "}
                <span className="font-bold text-gray-700">
                  {personToDelete?.legalName}
                </span>{" "}
                as a partner for this entity?
              </p>
              <p className="text-sm">
                This action cannot be undone. All the information related to
                this partner will be removed.
              </p>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  const partners = useMemo(() => {
    if (!partnershipData || !partnershipData.entityPeople) return [];
    return partnershipData.entityPeople.filter(
      (p) => p.personType === PartnershipPersonType.Partner,
    );
  }, [partnershipData]);

  const beneficiaries = useMemo(() => {
    if (!partnershipData.entityPeople) return [];
    return partnershipData.entityPeople.filter(
      (p) => p.personType === PartnershipPersonType.Beneficiary,
    );
  }, [partnershipData.entityPeople]);

  const beneficiaryOwnershipPercentage = useMemo(() => {
    if (!beneficiaries) return 0;
    const ownershipPercentage = beneficiaries.reduce((acc, curr) => {
      const currOwnershipPercentage = curr.ownershipPercentage
        ? parseFloat(curr.ownershipPercentage as unknown as string)
        : 0;
      return acc + (currOwnershipPercentage ?? 0);
    }, 0);
    return parseFloat(ownershipPercentage.toFixed(2));
  }, [beneficiaries]);

  function handleAddPartner() {
    if (!partnershipData) return;

    if (
      !entityTypePerson.legalName ||
      !entityTypePerson.email ||
      !entityTypePerson.address ||
      !emailChecker(entityTypePerson.email)
    )
      return;

    const partners = partnershipData.entityPeople ?? [];
    setPartnershipData({
      ...partnershipData,
      entityPeople: [
        ...partners,
        {
          ...entityTypePerson,
          personType: PartnershipPersonType.Partner,
        },
      ],
    });
    setShowAddPartnerModal(false);
    setEntityTypePerson({});
  }

  function handleAddBeneficiary() {
    if (!partnershipData) return;
    if (
      !entityTypePerson.ownershipPercentage ||
      !entityTypePerson.entityType ||
      !entityTypePerson.legalName ||
      !entityTypePerson.email ||
      !emailChecker(entityTypePerson.email)
    )
      return;
    const partners = partnershipData.entityPeople ?? [];
    setPartnershipData({
      ...partnershipData,
      entityPeople: [
        ...partners,
        {
          ...entityTypePerson,
          toBeKYC:
            entityTypePerson.ownershipPercentage && entityTypePerson.entityType
              ? entityTypePerson.ownershipPercentage >= 25 &&
                entityTypePerson.entityType === EntityTypes.SoleTraderIndividual
              : false,
          personType: PartnershipPersonType.Beneficiary,
        },
      ],
    });
    setShowAddBeneficiaryModal(false);
    setEntityTypePerson({});
  }

  function handleRemoveBeneficiary(email?: string) {
    const partners = partnershipData.entityPeople?.filter((p) => 
      p.personType !== PartnershipPersonType.Beneficiary ||
      p.email !== email
    ) ?? [];
    setPartnershipData({
      ...partnershipData,
      entityPeople: [...partners],
    });
  }

  function AddPartnerModal() {
    return (
      <Modal
        onPressCancel={() => {
          setEntityTypePerson({});
          setShowAddPartnerModal(false);
        }}
        open={showAddPartnerModal}
        setOpen={setShowAddPartnerModal}
        title="Add Partner"
        onPressAdd={() => handleAddPartner()}
      >
        <div className="flex flex-1 flex-col w-full">
          <TextInput
            label="Full Legal Name"
            value={entityTypePerson.legalName ?? ""}
            type="text"
            onChange={(e) => {
              setEntityTypePerson({
                ...entityTypePerson,
                legalName: e.target.value,
              });
            }}
            error={!entityTypePerson.legalName ? 1 : undefined}
          />

          <TextInput
            label="Email"
            type="email"
            onChange={(e) => {
              setEntityTypePerson({
                ...entityTypePerson,
                email: e.target.value,
              });
            }}
            error={
              !entityTypePerson.email || !emailChecker(entityTypePerson.email)
                ? 1
                : undefined
            }
            errormesage="Please enter a valid email!"
          />

          <div className="col-span-full border-b border-gray-200 py-2 px-6" />
          <p className="block text-md font-medium leading-6 text-apple-600 pt-4">
            Address
          </p>

          <AddressComponent
            required
            address={entityTypePerson.address ?? ({} as Address)}
            setAddress={(address) => {
              setEntityTypePerson({
                ...entityTypePerson,
                address,
              });
            }}
          />
        </div>
      </Modal>
    );
  }

  function AddBeneficiaries() {
    return (
      <Modal
        onPressCancel={() => {
          setEntityTypePerson({});
          setShowAddBeneficiaryModal(false);
        }}
        open={showAddBeneficiaryModal}
        setOpen={setShowAddBeneficiaryModal}
        title="Add Beneficial Owner"
        onPressAdd={() => handleAddBeneficiary()}
      >
        <div className="flex flex-1 flex-col w-full">
          <TextInput
            label="Full Legal Name"
            value={entityTypePerson.legalName ?? ""}
            type="text"
            onChange={(e) => {
              setEntityTypePerson({
                ...entityTypePerson,
                legalName: e.target.value,
              });
            }}
            error={!entityTypePerson.legalName ? 1 : undefined}
          />

          <TextInput
            label="Email"
            type="email"
            onChange={(e) => {
              setEntityTypePerson({
                ...entityTypePerson,
                email: e.target.value,
              });
            }}
            error={
              !entityTypePerson.email || !emailChecker(entityTypePerson.email)
                ? 1
                : undefined
            }
            errormesage="Please enter a valid email!"
          />

          <SelectInput
            label="Entity Type"
            onChange={(e) => {
              setEntityTypePerson({
                ...entityTypePerson,
                entityType: e.target.value as EntityTypes,
              });
            }}
            error={!entityTypePerson.entityType ? 1 : undefined}
          >
            <option value=""></option>
            {Object.entries(EntityTypes).map(([key, value]) => (
              <option key={key} value={value}>
                {value}
              </option>
            ))}
          </SelectInput>

          <TextInput
            label={`Ownership Percentage (${(100 - beneficiaryOwnershipPercentage).toFixed(2)}% remaining)`}
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            onChange={(e) => {
              if (
                parseFloat(e.target.value) >
                100.0 - beneficiaryOwnershipPercentage
              ) {
                alert(OWNERSHIP_PERCENTAGE_ERROR);
                e.target.value = "";
                return;
              } else {
                setEntityTypePerson({
                  ...entityTypePerson,
                  ownershipPercentage: parseFloat((+e.target.value).toFixed(2)),
                });
              }
            }}
            error={
              !entityTypePerson.ownershipPercentage ||
              +entityTypePerson.ownershipPercentage < 25 ||
              +entityTypePerson.ownershipPercentage >
                100 - beneficiaryOwnershipPercentage
                ? 1
                : undefined
            }
            errormesage={"Ownership percentage must be 25% or more!"}
          />
        </div>
      </Modal>
    );
  }

  return (
    <div className="flex flex-col w-full flex-1 lg:p-4">
      {AddPartnerModal()}
      {AddBeneficiaries()}
      {AreYouSurePartner()}
      {<OverlayLoading open={attachmentLoading || deleteLoading} />}
      {!partnershipData || !nodeData ? (
        <Loading />
      ) : (
        <>
          <div className="flex flex-row justify-between items-center border-b border-gray-200 pb-8">
            <div>
              <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
                Partnership
              </h2>
              <p className="mt-1 text-xs text-gray-500">
                Please fill all the required information to proceed with your
                KYB application.
              </p>
            </div>

            {/* <div>
              <Button> Save Progress</Button>
            </div> */}
          </div>

          <div className="flex flex-col items-center w-full mt-4 px-1 sm:px-4">
            <div className="flex flex-col w-full">
              <div className="flex flex-row justify-between items-center w-full">
                <div className="w-1/2">
                  <p className="block text-md font-medium leading-6 text-apple-600 ">
                    Partners
                  </p>
                  <p className="mt-1 text-xs text-gray-500">
                    Please provide the full-name and residential address of all
                    partners.
                  </p>
                </div>

                {!determineDisabled(kybStatus) ? (
                  <div>
                    <button
                      className="flex w-full rounded-xl hover:bg-apple-500 bg-transparent border-2 border-apple-600 py-0.5 hover:text-white hover:border-apple-500 px-4 text-sm font-semibold text-apple-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-apple-600"
                      onClick={() => setShowAddPartnerModal(true)}
                    >
                      Add Partner
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col w-full px-2 sm:px-4">
                {partners.length < 1 ? (
                  <EmptyPlaceholder>
                    No partners added yet. Please add partner.
                  </EmptyPlaceholder>
                ) : (
                  <>
                    {partners.map((person, index) => (
                      <div
                        key={index}
                        className={
                          index < partners.length - 1
                            ? `border-b border-gray-200`
                            : ``
                        }
                      >
                        <div className="flex flex-row justify-between items-center py-4 w-full">
                          <div className="flex flex-row justify-start items-center w-1/2 flex-wrap">
                            <span className="inline-flex h-8 w-8 items-center justify-center rounded-xl bg-apple-200 mr-4">
                              <span className="text-xs font-medium leading-none text-apple-800">
                                {person.legalName?.charAt(0).toUpperCase()}
                              </span>
                            </span>
                            <div className="">
                              <div className="flex items-start gap-x-3">
                                <p className="text-sm font-semibold text-ellipsis leading-6 text-gray-900">
                                  {person.legalName}
                                </p>
                              </div>
                              <p className="truncate text-xs leading-5 text-ellipsis text-gray-500">
                                {person.email}
                              </p>
                            </div>
                          </div>
                          {!determineDisabled(kybStatus) ? (
                            <div className="flex flex-row justify-end items-center">
                              <button
                                className="flex w-full rounded-xl hover:bg-red-500 bg-transparent border-2 border-red-500 py-0.5 hover:text-white hover:border-red-500 px-4 text-sm font-semibold text-red-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                onClick={() => {
                                  setPersonToDelete(person);
                                  setAreYouSurePartner(true);
                                }}
                              >
                                Remove
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ))}
                    <SelectInput
                      disabled={determineDisabled(kybStatus)}
                      label="Please select a partner to undergo the Know Your Customer process"
                      value={
                        partners
                          .find((p) => p.toBeKYC)
                          ?.email?.concat(
                            partners.find((p) => p.toBeKYC)?.legalName ?? "",
                          ) ?? ""
                      }
                      onChange={(e) => {
                        if (e.target.value.length) {
                          const person = partners.find(
                            (p) =>
                              p.email?.concat(p.legalName ?? "") ===
                              e.target.value,
                          );
                          if (!person) return;
                          const newPartners = partnershipData.entityPeople?.map(
                            (p) => {
                              if (
                                p.legalName === person.legalName &&
                                p.email === person.email &&
                                p.personType === person.personType
                              ) {
                                return {
                                  ...p,
                                  toBeKYC: true,
                                };
                              }
                              return {
                                ...p,
                                toBeKYC:
                                  p.personType === person.personType
                                    ? false
                                    : p.toBeKYC,
                              };
                            },
                          );
                          setPartnershipData({
                            ...partnershipData,
                            entityPeople: newPartners,
                          });
                        } else {
                          const newPartners = partnershipData.entityPeople?.map(
                            (p) => {
                              return { ...p, toBeKYC: false };
                            },
                          );
                          setPartnershipData({
                            ...partnershipData,
                            entityPeople: newPartners,
                          });
                        }
                      }}
                      error={
                        partners.find((p) => p.toBeKYC)?.email ? undefined : 1
                      }
                    >
                      <option value=""></option>
                      {partners.map((person, index) => (
                        <option
                          key={index}
                          value={person.email?.concat(person.legalName ?? "")}
                        >
                          {person.legalName}
                        </option>
                      ))}
                    </SelectInput>
                    <SelectInput
                      label="Do you have a Partnership Agreement?"
                      disabled={determineDisabled(kybStatus)}
                      onChange={(e) => {
                        setPartnershipData({
                          ...partnershipData,
                          hasPartnershipAgreement: !e.target.value
                            ? undefined
                            : e.target.value === "1"
                              ? true
                              : false,
                        });
                      }}
                      error={
                        partnershipData.hasPartnershipAgreement === undefined
                          ? 1
                          : undefined
                      }
                    >
                      <option value={""}></option>
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </SelectInput>
                  </>
                )}
              </div>
            </div>
            <div className="w-full border-b border-gray-200 py-4 px-6" />
            <div className="flex flex-col items-center w-full mt-4">
              <div className="flex flex-row justify-between items-center w-full ">
                <div className="w-1/2">
                  <p className="block text-md font-medium leading-6 text-apple-600 ">
                    Beneficial Owners
                  </p>
                  <p className="mt-1 text-xs text-gray-500">
                    A <strong>Beneficial Owner</strong> is a sole
                    trader/individual or entity who owns, or otherwise controls
                    the business of an entity. Please provide the required
                    details for all beneficial owners.
                  </p>
                </div>
                <div>
                  {beneficiaryOwnershipPercentage < 100 &&
                  !determineDisabled(kybStatus) ? (
                    <button
                      className="flex w-full rounded-xl hover:bg-apple-500 bg-transparent border-2 border-apple-500 py-0.5 hover:text-white hover:border-apple-500 px-4 text-sm font-semibold text-apple-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-apple-600"
                      onClick={() => setShowAddBeneficiaryModal(true)}
                    >
                      Add Owners
                    </button>
                  ) : null}
                </div>
              </div>
              <div className="flex flex-col w-full px-2 sm:px-4">
                {beneficiaries.length < 1 ? (
                  <EmptyPlaceholder>
                    No beneficial owners added yet. Please add all beneficial
                    owners.
                  </EmptyPlaceholder>
                ) : (
                  beneficiaries.map((person, index) => (
                    <div
                      key={index}
                      className={
                        index < beneficiaries.length - 1 &&
                        beneficiaries.length > 1
                          ? `border-b border-gray-200`
                          : ``
                      }
                    >
                      <div className="flex flex-row justify-between items-center py-4 w-full flex-wrap">
                        <div className="flex flex-row justify-start items-center ">
                          <span className="inline-flex h-8 w-8 items-center justify-center rounded-xl bg-apple-200 mr-4">
                            <span className="text-xs font-medium leading-none text-apple-800">
                              {person.legalName?.charAt(0).toUpperCase()}
                            </span>
                          </span>
                          <div className="">
                            <div className="flex items-start gap-x-3">
                              <p className="text-sm font-semibold leading-6 text-gray-900">
                                {person.legalName}
                              </p>
                            </div>
                            <p className="truncate text-xs leading-5 text-gray-500">
                              {person.ownershipPercentage}% Ownership
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-row justify-end items-center">
                          <Badge variant="disabled">{person.entityType}</Badge>
                        </div>
                        <div className="flex flex-row justify-end items-center">
                          <button
                            type="button"
                            className="flex w-full rounded-xl hover:bg-red-500 bg-transparent border-2 border-red-500 py-0.5 hover:text-white hover:border-red-500 px-4 text-sm font-semibold text-red-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                            onClick={() => {
                              if (
                                confirm(
                                  "Are you sure you want to delete this owner?\n\nThis action cannot be undone. All the information related to this partner will be removed.",
                                )
                              ) {
                                handleRemoveBeneficiary(person.email);
                              }
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>

            <div className="w-full border-b border-gray-200 py-4 px-6" />
            <div className="w-full">
              {data ? (
                <div className="w-full">
                  {AttachmentsModals({
                    nodeData: data,
                    openAttachmentModal,
                    setOpenAttachmentModal,
                    setAttachmentLoading,
                    areyousure,
                    setAreyousure,
                    attachment,
                    setAttachment,
                    mutateNodeAttachments,
                    removeAttachment,
                    AttachmentType: partnershipData.hasPartnershipAgreement
                      ? PartnershipAttachmentType
                      : AlternatePartnershipAttachmentType,
                    kybStatus,
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
